import * as React from "react"
import styled from "styled-components"
import { base} from "../styles/ColorStyles"
import { Pmono, H1 } from "../styles/Primitives"

export default function About() {

  console.log(
    `Hello 👋 you snoppy detective. Let's get a coffee and meet for real sometime.`
  )
 
  return (
    <section>
      <Wrapper>
        <Pmono>Hi, I’m Atharva 👋🏻</Pmono>
        <H1>Product designer, amateur engineer & an iteration in progress...</H1>
      </Wrapper>
    </section>
  )
}

const Wrapper = styled.div`
  padding: 6rem 1.5rem 4rem;
  margin: 0;
  border-bottom: 1px dashed ${base.border};
`