import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/home/About"
import SelectWork from "../components/home/SelectWork"

const IndexPage = () => (
  <Layout>
    <Seo title="Work | Atharva Patil" />
    <About />
    <SelectWork />
  </Layout>
)

export default IndexPage
